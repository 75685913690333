import se_logo_icon from '../res/superellipse-logo.svg'
import big_logo from '../res/big-logo.svg'
import arrowright_icon from '../res/arrow-narrow-right.svg'

const IntroPage: React.FC = () => {

  const goToDemo = () => {
    window.location.href = `https://super-ellipse.ru/login`
  }

  return (
    <div className='background_main'>
    <div className='background'>
    <div>
      <div className='header1'>
        <img className='logo' src={se_logo_icon} alt=''/>
      </div>
      
        <div className='bodydiv1'>
          <div className='bodydiv2'>
            <button className='bodyupperbutton'>
              <div className='bodyupperbuttontext'>Демоверсия</div>
            </button>
            <div className='bodydiv3'>
              <div className='bodydiv3text1'>Ellipse - система комфортного ведения бизнеса</div>
              <div className='bodydiv3text2'>Единое решение для управления проектами и коммуникации вашей команды</div>
            </div>
          </div>
          <button className='bodylowerbutton' onClick={goToDemo}>
            <div className='bodylowerbuttontext'>Демоверсия</div>
            <img className='bodylowerbuttonpic' src={arrowright_icon} alt='' />
          </button>
        </div>
      </div>
      <img className='big-logo' src={big_logo}/>
    </div>
    </div>
  )
}

export default IntroPage